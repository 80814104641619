<template>
    <div v-if="!fill" :class="[nonFillClasses]">
        <div class="hidden flex-row space-x-1 p-2 lg:flex">
            <button v-for="(button, i) in views" :key="i" :disabled="button.disabled" :class="getButtonClasses(button)"
                @click="emitView(button)">
                {{ button.title }}
            </button>
        </div>
        <DropDown class="flex lg:hidden" @optionSelected="onSelect" :selected-options="value" :options="selectOptions"
            width="w-full" button-classes="py-3" />
    </div>
    <!-- <div v-if="!fill">
        <div :class="[subMenu ? 'text-center space-x-2 w-fit text-sm pt-1' : 'items-center h-full space-x-3', 'flex']">
            <button v-for="(button, i) in views" :key="i" :disabled="button.disabled" :class="getButtonClasses(button.slug)"
                @click="emitView(button)">
                {{ button.title }}
            </button>
        </div>
    </div> -->
    <div v-else class="w-full flex space-x-2">
        <button v-for="(button, i) in views" :key="i" class="flex-grow px-1 py-1 text-center hidden lg:flex"
            :class="[subMenu ? 'text-sm' : '', getButtonClasses(button)]" :disabled="button.disabled"
            @click="emitView(button)">
            {{ button.title }}
        </button>
        <div class="flex justify-center w-full lg:hidden">
            <DropDown class="flex" @optionSelected="onSelect" :selected-options="value" :options="selectOptions"
                width="w-full" button-classes="py-3" />
        </div>
    </div>
</template>
<script>
import DropDown from "@/components/utils/DropDown.vue";

export default {
    name: 'TabsBar',

    components: {
        DropDown
    },

    props: {
        views: Array,
        errors: {
            type: Array,
            default: () => {
                return []
            }
        },
        subMenu: {
            type: Boolean,
            default: false
        },
        value: {
            type: String,
            required: true,
        },
        nonFillClasses: {
            type: String,
            default: 'flex h-full w-full items-center justify-center',
        },
        fill: {
            type: Boolean,
            default: false
        },
    },

    computed: {
        selectOptions() {
            return this.views
                .filter(v => !v.disabled)
                .map(v => {
                    return {
                        value: v.slug,
                        title: v.title
                    }
                })
        }
    },

    methods: {
        getButtonClasses(button) {
            const buttonSlug = button.slug
            const disabled = button.disabled

            let classes = ['flex h-10 w-fit cursor-pointer items-center justify-center px-2 router-link-exact-active active border-b-4 font-medium transition-colors duration-200']

            if (this.value == buttonSlug) {
                if (this.errors.includes(buttonSlug)) {
                    classes.push('border-red-700 text-red-700 hover:text-red-700 hover:border-red-700')
                } else {
                    classes.push('border-hs-green-400 text-gray-700')
                    if (this.subMenu) {
                        classes.push('')
                    } else {
                        classes.push('')
                    }
                }
            } else {
                if (this.errors.includes(buttonSlug)) {
                    classes.push('border-red-300 text-red-500 hover:text-red-700 hover:border-red-700 animate-pulse')
                } else if (!disabled) {
                    classes.push('border-gray-300 text-gray-500 hover:border-hs-green-100 hover:text-gray-700')
                }
            }

            return classes
        },

        onSelect(slug) {
            this.emitView({ slug: slug });
        },

        emitView(button) {
            if (!button.disabled) {
                this.$emit('input', button.slug)
            }
        }
    }
}
</script>